var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Section',{attrs:{"title":_vm.$t('sharesIssuances')},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"text-right"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$can('create', 'Valuation'))?_c('v-btn',_vm._g(_vm._b({staticClass:"min-width-2",attrs:{"color":"secondary","depressed":"","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("shared.new"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1):_vm._e()]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.$router.push({ path: '/shares/create-shares-issuance' })}}},[_vm._v(_vm._s(_vm.$t("addOne")))])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.$router.push({ path: '/shares/create-batch-shares-issuance' })}}},[_vm._v(_vm._s(_vm.$t("addBatch")))])],1)],1)],1)],1)]},proxy:true}])},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex justify-center mt-5"},[_c('div',{staticClass:"mx-5"},[_c('span',{staticClass:"ma-2 text-subtitle-1"},[_vm._v(_vm._s(_vm.$t("sharesIssued")))]),_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$n(_vm.shares)))])]),_c('div',{staticClass:"mx-5"},[_c('span',{staticClass:"ma-2 text-subtitle-1"},[_vm._v(_vm._s(_vm.$t("sharePrice")))]),_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$n(_vm.sharePrice, "currency")))])])])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('Grid',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"search":""},scopedSlots:_vm._u([{key:"item.stakeholder.name",fn:function(ref){
var item = ref.item;
return [(!item.stakeholder)?_c('span',[_vm._v(_vm._s(_vm.$t('notSelected')))]):(item.status == 'drafted')?_c('router-link',{attrs:{"to":{
              name: 'edit-shares-issuance',
              params: { id: item.id },
            }}},[_vm._v(" "+_vm._s(item.stakeholder.name)+" ")]):_c('router-link',{attrs:{"to":{
              name: 'view-shares-issuance',
              params: { id: item.id },
            }}},[_vm._v(" "+_vm._s(item.stakeholder.name)+" ")])]}},{key:"item.count",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.count))+" ")]}},{key:"item.grantPrice",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.grantPrice, "currency"))+" ")]}},{key:"item.issueDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(item.issueDate))+" ")]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatusText(item.status))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }