





































































































































































































































































































































import Vue, { PropType } from "vue";

import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";
import DateField from "@/components/DateField.vue";
import {
  getStakeholder,
  getStakeholdersOfCompany,
  StakeholderDto,
} from "@/service/stakeholders/stakeholders.service";
import {
  updateSharesIssuance,
  getSharesIssuanceDraft,
  deleteSharesIssuance,
} from "@/service/shares-issuances/shares-issuances.service";
import ComplexConfirmDialog from "@/components/ComplexConfirmDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import dayjs from "dayjs";
import { shareClassesService } from "@/service";
import { DocumentDto } from "@/service/documents/documents.service.dto";
import { Filter, getDocumentsOf } from "@/service/documents/documents.service";

export default Vue.extend({
  components: {
    DateField,
    ComplexConfirmDialog,
    ConfirmDialog
  },
  props: {
    id: {
      type: String,
      required: true
    },
    filter: {
      type: Object as PropType<Filter>,
      required: false,
      default: undefined,
    }
  },
  data() {
    return {
      loading: false,
      stakeHolders: [] as Array<StakeholderDto>,
      documents: [] as Array<DocumentDto>,
      selectedStakeholderId: "" as string | undefined,
      selectedStakeholder: null as StakeholderDto | null,
      sharesSize: undefined as number | undefined,
      sharePrice: "",
      issuanceDate: dayjs().startOf('day').format('YYYY-MM-DD'),
      directorFile: undefined as string | undefined,
      shareHolderFile: undefined as File | undefined,
      saveAsDraft: !!this.id,
      showConfirmDialog: false,
      showDeleteDialog: false,
      shareClasses: [] as Array<shareClassesService.ShareClassDto>,
      shareClassId: "",
      documentTypeFilter: "" as DocumentType | "",
      createCertificate: false,
      isFreeCompany: true,
      totalInvestment: 0
    };
  },
  computed: {
    companySharePercent(): number {
      return ((this.sharesSize! /( this.sharesSize! + companiesStore.state.current!.shares)) * 100) || 0;
    },
    stakeHolderFullAddress(): string {
      return this.selectedStakeholder && this.selectedStakeholder.contact
        ? `${this.selectedStakeholder.contact.address.streetAddress}, ${this.selectedStakeholder.contact.address.city} ${this.selectedStakeholder.contact.address.state}, ${this.selectedStakeholder.contact.address.country}`
        : "";
    },
  },
  watch: {
    async selectedStakeholderId(newValue) {
      if (newValue) this.selectedStakeholder = await getStakeholder(newValue);
    },
  },
  async mounted() {
    if (!companiesStore.state.currentId) return;

    try {
      this.loading = true;
      this.fetchData();
      
        var results = await Promise.all([
          getStakeholdersOfCompany(companiesStore.state.currentId, ["contact"]),
          this.loadShareIssuanceData(),
          getDocumentsOf(this.filter || {
            entity: "company",
            id: companiesStore.state.currentId,
          },
          this.documentTypeFilter == "" ? undefined : this.documentTypeFilter
          ),
          shareClassesService.getAllShareClassesOfCompany(companiesStore.state.currentId)
        ]);

        this.stakeHolders = results[0];
        this.documents = results[2];
        this.shareClasses =results[3];
      
      this.loading = false;
    } catch (error) {
      appStore.snackbar(this.$t("shared.genericError").toString(), "error");
    }
  },
  methods: {
    async fetchData() {
      if (!companiesStore.state.currentId) return;

      try {
        if(companiesStore.state.current)
          this.isFreeCompany = companiesStore.state.current.isFree;
       
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    async onCommitClick() {
      if (this.saveAsDraft) this.onConfirmClick();
      else this.showConfirmDialog = true;
    },
    async onConfirmClick() {
      try {
        this.loading = true;
        
        await updateSharesIssuance({
          id: this.id,
          stakeholderId: this.selectedStakeholderId,
          sharesSize: this.sharesSize,
          issueDate: dayjs.utc(this.issuanceDate, 'YYYY-MM-DD').startOf('day').toDate(),
          directorFile: this.directorFile,
          shareholderFile: this.shareHolderFile,
          grantPrice: parseFloat(this.sharePrice),
          status: this.saveAsDraft ? "drafted" : "pending",
        });

        if (!this.saveAsDraft) {
          // invalidate current company's shares count
          await companiesStore.fetchList();
        }

        this.$router.go(-1);
      } catch (error) {
        this.loading = false;
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    async loadShareIssuanceData() {
      try {
        const si = await getSharesIssuanceDraft(this.id);

        this.selectedStakeholderId = si.stakeholder?.id;
        this.sharesSize = si.count;
        this.sharePrice = si.grantPrice?.toString() ?? '';
        this.issuanceDate = si.issueDate.toISOString().substr(0, 10);
        if (si.shareholderFile) {
          const f = new File([""], si.shareholderFile.name, {
            type: si.shareholderFile.mimeType,
          });
          this.shareHolderFile = f;
        }
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    async onDeleteConfirm() {
      if (!this.id) return;

      try {
        this.loading = true;
        await deleteSharesIssuance(this.id);
        appStore.snackbar(
          this.$t("shared.successfulExecution").toString(),
          "success"
        );
        this.loading = false;
        this.$router.go(-1);
      } catch (error) {
        this.loading = false;
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    async calculateTotalInvestment(){
      if(this.sharesSize && this.sharePrice){
        this.totalInvestment = this.sharesSize*parseFloat(this.sharePrice);
      }
    },
  },
});
