














































































































































































































































































import Vue, { PropType } from "vue";

import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";
import DateField from "@/components/DateField.vue";
import {
  getStakeholdersOfCompany,
  StakeholderDto,
} from "@/service/stakeholders/stakeholders.service";
import { shareClassesService } from "@/service";
import { DocumentDto } from "@/service/documents/documents.service.dto";
import { getDocumentsOf } from "@/service/documents/documents.service";
import { ShareIssuanceFormData } from "./NewShareIssuanceDialog";
import { cloneDeep } from "lodash";
import { ShareClassDto } from "@/service/share-classes/share-classes.service.dto";

export default Vue.extend({
  components: {
    DateField,
  },
  props: {
    value: {
      required: true,
      type:  Object as PropType<ShareIssuanceFormData>,
    },
  },
  data() {
    return {
      loading: false,
      stakeHolders: [] as Array<StakeholderDto>,
      documents: [] as Array<DocumentDto>,
      shareClasses: [] as Array<shareClassesService.ShareClassDto>,
      isFreeCompany: true,
      form: cloneDeep(this.value)
    };
  },
  computed: {
    totalInvestment(): number {
      return this.sharePriceNumber * this.sharesSizeNumber;
    },
    sharePriceNumber(): number {
      return parseFloat(this.form.sharePrice) || 0;
    },
    sharesSizeNumber(): number {
      return parseFloat(this.form.sharesSize) || 0;
    },
    companySharePercent(): number {
      return ((this.sharesSizeNumber / (this.sharesSizeNumber + companiesStore.state.current!.shares)) * 100) || 0;
    },
    selectedStakeholder(): StakeholderDto | undefined{
      return !this.form.stakeholder.id ? undefined : this.stakeHolders.find(x => x.id == this.form.stakeholder.id);
    },
    selectedShareClass(): ShareClassDto | undefined{
      return !this.form.shareClass.id ? undefined : this.shareClasses.find(x => x.id == this.form.shareClass.id);
    },
    stakeHolderFullAddress(): string {
      return this.selectedStakeholder && this.selectedStakeholder.contact
        ? `${this.selectedStakeholder.contact.address.streetAddress}, ${this.selectedStakeholder.contact.address.city} ${this.selectedStakeholder.contact.address.state}, ${this.selectedStakeholder.contact.address.country}`
        : "";
    },
  },
  watch: {
    form: {
      deep: true,
      handler(value) {
        this.$emit("input", cloneDeep(value));
      }
    },
    'selectedStakeholder.name'() {
        this.form.stakeholder.name = this.selectedStakeholder?.name
    },
    'selectedShareClass.name'() {
        this.form.shareClass.name = this.selectedShareClass?.name;
    }
    
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!companiesStore.state.currentId) return;

      this.loading = true;
      try {
        
          this.isFreeCompany = companiesStore.state.current!.isFree;

          var results = await Promise.all([
          getStakeholdersOfCompany(companiesStore.state.currentId, ["contact"]),
          getDocumentsOf({
            entity: "company",
            id: companiesStore.state.currentId,
          }),
          shareClassesService.getAllShareClassesOfCompany(
        companiesStore.state.currentId
      )
        ]);

          this.stakeHolders = results[0];
          this.documents =  results[1];
          this.shareClasses = results[2]
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      } finally {
        this.loading = false;
      }
    }
  },
});
