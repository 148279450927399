







































import Vue from "vue";
import dayjs from "dayjs";
import { shareClassesService } from "@/service";
import { ShareIssuanceFormData } from "./NewShareIssuanceDialog";
import NewShareIssuanceForm from './NewShareIssuanceForm.vue';

export default Vue.extend({
  components: {
    NewShareIssuanceForm
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    }
  },
  data() {
    return {
      loading: false,
      shareClasses: [] as Array<shareClassesService.ShareClassDto>,
      isFreeCompany: true,
      formData : {
         stakeholder: { 
          id: "",
          name: ""
        },
        sharesSize: "",
        sharePrice: "",
        shareClass: {
          id: '',
          name: ''
        },
        issueDate: dayjs().startOf('day').format('YYYY-MM-DD'),
        directorFile: undefined as string | undefined,
        shareHolderFile: undefined as File | undefined,
        createCertificate: false,
      } as ShareIssuanceFormData
    };
  },
  computed: {
    opened: {
      get(): boolean {
        return this.value;
      },
      set(v: boolean): void {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      this.opened = false;
    },
    cancel() {
      this.$emit("cancel");
      this.opened = false;
    },
    async onCommitClick() {
      
      this.$emit('confirm', this.formData);

      this.opened = false;
    },
  },
});
