




















































































































import Vue from "vue";
import { MenuAction, MenuActions } from "@/components/menu-actions";
import Section from "@/components/Section.vue";
import { appStore } from "@/app.store";
import {
  getSharesIssuancesAnalysis,
  SharesIssuanceAnalysisDto,
  SharesIssuanceStatus,
} from "@/service/shares-issuances/shares-issuances.service";
import { StakeholderDto } from "@/service/stakeholders/stakeholders.service.dto";

const statusTextMap: Record<SharesIssuanceStatus, string> = {
  pending: "pending",
  drafted: "draft",
  issued: "issued",
};

export default Vue.extend({
  components: {
    MenuActions,
    Section,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    actions: [] as Array<MenuAction>,
    sharesIssuance: undefined! as SharesIssuanceAnalysisDto,
    shareholder: undefined as StakeholderDto | undefined,
  }),
  computed: {
    totalPrice(): number {
      return this.sharesIssuance.grantPrice * this.sharesIssuance.count;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.sharesIssuance = await getSharesIssuancesAnalysis(this.id);
        this.shareholder = this.sharesIssuance.stakeholder;
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    getStatusText(status: SharesIssuanceStatus): string {
      return this.$t(statusTextMap[status]).toString();
    },
  },
});
