





















































































import Vue from "vue";
import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";
import {
  getSharesIssuancesAnalysisOfCompany,
  SharesIssuanceDto,
  SharesIssuanceStatus,
} from "@/service/shares-issuances/shares-issuances.service";
import Section from "@/components/Section.vue";
import Grid from "@/components/grid/Grid.vue";

const statusTextMap: Record<SharesIssuanceStatus, string> = {
  drafted: "draft",
  pending: "pending",
  issued: "issued",
};

export default Vue.extend({
  components: {
    Section,
    Grid,
  },
  data() {
    return {
      loading: false,
      sharePrice: companiesStore.state.current?.sharePrice ?? 0,
      shares: companiesStore.state.current?.shares ?? 0,
      items: [] as Array<SharesIssuanceDto>,
      headers: [
        {
          text: this.$t("shareHolder").toString(),
          align: "start",
          sortable: false,
          value: "stakeholder.name",
        },
        {
          text: this.$t("shares").toString(),
          align: "start",
          sortable: false,
          value: "count",
        },
        {
          text: this.$t("grantPrice").toString(),
          align: "start",
          sortable: false,
          value: "grantPrice",
        },
        {
          text: this.$t("issueDate").toString(),
          align: "start",
          sortable: false,
          value: "issueDate",
        },
        {
          text: this.$t("status").toString(),
          align: "start",
          sortable: false,
          value: "status",
        },
      ],
    };
  },
  async mounted() {
    if (!companiesStore.state.currentId || !companiesStore.state.current)
      return;

    try {
      this.loading = true;
      this.items = await getSharesIssuancesAnalysisOfCompany(
        companiesStore.state.current.id
      );
      this.loading = false;
    } catch (error) {
      appStore.snackbar(this.$t("shared.genericError").toString(), "error");
    }
  },
  methods: {
    getStatusText(status: SharesIssuanceStatus): string {
      return this.$t(statusTextMap[status]).toString();
    },
  },
});
