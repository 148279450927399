







































import Vue from "vue";

import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";
import ComplexConfirmDialog from "@/components/ComplexConfirmDialog.vue";
import {ShareIssuanceFormData} from "@/components/shares/NewShareIssuanceDialog";
import dayjs from "dayjs";
import { createSharesIssuance } from "@/service/shares-issuances/shares-issuances.service";
import NewShareIssuanceForm from '@/components/shares/NewShareIssuanceForm.vue';

export default Vue.extend({
  components: {
    ComplexConfirmDialog,
    NewShareIssuanceForm
  },
  data() {
    return {
      uploading: false,
      showConfirmDialog: false,
      saveAsDraft: false,
      formData : {
         stakeholder: { 
          id: "",
          name: ""
        },
        sharesSize: "",
        sharePrice: "",
        shareClass: {
          id: '',
          name: ''
        },
        issueDate: dayjs().startOf('day').format('YYYY-MM-DD'),
        directorFile: undefined as string | undefined,
        shareHolderFile: undefined as File | undefined,
        createCertificate: false,
      } as ShareIssuanceFormData
    };
  },
  methods: {
    async onCommitClick() {
      if (this.saveAsDraft) this.onConfirmClick();
      else this.showConfirmDialog = true;
    },
    async onConfirmClick() {
      try {
        this.uploading = true;
        
        await createSharesIssuance({
          stakeholderId: this.formData.stakeholder.id!,
          sharesSize: parseFloat(this.formData.sharesSize),
          issueDate: dayjs.utc(this.formData.issueDate, 'YYYY-MM-DD').startOf('day').toDate(),
          directorFile: this.formData.directorFile,
          shareholderFile: this.formData.shareholderFile,
          grantPrice: parseFloat(this.formData.sharePrice),
          status: this.saveAsDraft ? "drafted" : "pending",
          createCertificate: this.formData.createCertificate,
          shareClassId: this.formData.shareClass.id!
        });

        if (!this.saveAsDraft) {
          // invalidate current company's shares count
          await companiesStore.fetchList();
        }

        this.$router.go(-1);
      } catch (error) {
        this.uploading = false;
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
  },
});
