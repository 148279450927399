

























































































































import Vue from "vue";

import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";
import ComplexConfirmDialog from "@/components/ComplexConfirmDialog.vue";
import NewShareIssuanceDialog from "@/components/shares/NewShareIssuanceDialog.vue";
import {ShareIssuanceFormData} from "@/components/shares/NewShareIssuanceDialog";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import dayjs from "dayjs";
import { createBatchSharesIssuance } from "@/service/shares-issuances/shares-issuances.service";

export default Vue.extend({
  components: {
    ComplexConfirmDialog,
    NewShareIssuanceDialog,
    ConfirmDialog
  },
  data() {
    return {
      uploading: false,
      showConfirmDialog: false,
      showDeleteDialog: false,
      showNewShareIssuanceDialog: false,
      issuances: [] as Array<ShareIssuanceFormData>,
      selectdIssuanceIndex: -1,
      uploadedItems: 0
    };
  },
  computed: {
    selectedItem(): ShareIssuanceFormData | undefined {
      return this.selectdIssuanceIndex == -1 ? undefined : this.issuances[this.selectdIssuanceIndex];
    }
  },
  methods: {
    addItem() {
      this.selectdIssuanceIndex = -1;
      this.showNewShareIssuanceDialog = true;
    },
    editItem(index: number) {
      this.selectdIssuanceIndex = index;
      this.showNewShareIssuanceDialog = true;
    },
    deleteItem(index: number) {
      this.selectdIssuanceIndex = index;
      this.showDeleteDialog = true;
    },
    onDeleteConfirm() {

      this.issuances.splice(this.selectdIssuanceIndex, 1);
    },
    onNewShareIssuanceDialogCommit(issuance: ShareIssuanceFormData) {
      console.debug('issuance', issuance);
      if (this.selectdIssuanceIndex == -1) {
        this.issuances.push(issuance);
      }
      else {
        Vue.set(this.issuances, this.selectdIssuanceIndex, issuance);
      }
    },
    async onCommitClick() {
      this.showConfirmDialog = true;
    },
    getUploadingState(rowIndex: number) : 'pending' | 'uploading' | 'done' {
      if (rowIndex > this.uploadedItems)
        return 'pending';
      if (rowIndex == this.uploadedItems) 
        return 'uploading';
      
      return 'done';
    },
    async onConfirmClick() {
      try {
        this.uploading = true;
        this.uploadedItems = 0;
        await createBatchSharesIssuance(this.issuances.map( x=> ({
          stakeholderId: x.stakeholder.id!,
          sharesSize: parseFloat(x.sharesSize),
          issueDate: dayjs.utc(x.issueDate, 'YYYY-MM-DD').startOf('day').toDate(),
          directorFile: x.directorFile,
          shareholderFile: x.shareholderFile,
          grantPrice: parseFloat(x.sharePrice),
          status: "pending",
          createCertificate: x.createCertificate,
          shareClassId: x.shareClass.id!
        })), (uploaded, total) => {
          this.uploadedItems = uploaded;
        });

        
        // invalidate current company's shares count
        await companiesStore.fetchList();
        

        this.$router.go(-1);
      } catch (error) {
        this.uploading = false;
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
  },
});
